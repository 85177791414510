@layer modules, ui, base;
@layer ui {
  .sk-SearchableMultiSelect_main__pu6A3 {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-8);
}

.sk-SearchableMultiSelect_optionTags__wyOO8 {
  display: flex;
  flex-wrap: wrap;
  gap: var(--sk-space-8);
}

}
